import $axios from "axios";

window.$clearAxios = $axios;

window.$axios = $axios.create({
    baseURL: "/api",
    headers: {
        Authorization: `Bearer ${document.querySelector("meta[name=\"auth-token\"]").getAttribute("content")}`
    }
});

window.$axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response) {
        return Promise.reject(error.response);
    }
    return Promise.reject(error);
});
